import styled from "styled-components";

export const StyledMyliu = styled.div`
	position: absolute;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 32px;
	color: white;
	font-family: "PT Mono", monospace;

	img {
		width: 300px;
		height: auto;
	}

	> * {
		text-align: center;
		padding: 0 32px;
	}
`;

export const Myliu = () => {
	return (
		<StyledMyliu>
			<h1>To the most wonderful person on this planet</h1>
			<img src="https://i.imgur.com/q8THL4J.jpg" alt="valentine card" />
			<p>Will u be my valentine?</p>
			<p>
				It's been a long time since I've felt so much love and I wanna share it
				w u :3
			</p>
		</StyledMyliu>
	);
};
