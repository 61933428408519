import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Cover } from "./pages/home/components/Stars";
import { Canvas } from "@react-three/fiber";
import { StandardBody } from "./common/StandardBody";
import { Error404 } from "./pages/error/404";
import { Myliu } from "./pages/myliu/Myliu";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<StandardBody />}>
					<Route
						index
						element={
							<>
								<div className="overlay" />
								<Canvas
									camera={{ position: [0, 0, 1], zoom: 0.5 }}
									style={{ width: "100vw", height: "100vh" }}
								>
									<Cover />
								</Canvas>
							</>
						}
					/>
				</Route>
				<Route path="/myliu">
					<Route
						index
						element={
							<>
								<div className="overlay" />
								<Myliu />
								<Canvas
									camera={{ position: [0, 0, 1], zoom: 0.5 }}
									style={{ width: "100vw", height: "100vh" }}
								>
									<Cover />
								</Canvas>
							</>
						}
					/>
					<Route path="*" element={<Error404 />} />
				</Route>
				<Route path="*" element={<Error404 />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
