import { StyledActions, StyledBrand, StyledNavbar } from "./Navbar.styled";

export const Navbar = () => {
	return (
		<StyledNavbar>
			<StyledBrand href="https://setkauskas.lt">
				setkauskas.lt<span className="blinker">_</span>
			</StyledBrand>
			<StyledActions>
				<a href="mailto:jokubas@setkauskas.lt">Say hi</a>
			</StyledActions>
		</StyledNavbar>
	);
};
