import styled from "styled-components";

export const StyledNavbar = styled.div`
	width: 100%;
	border-bottom: rgba(255, 255, 255, 0.1) solid 1px;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	background: rgba(255, 255, 255, 0.05);
	backdrop-filter: blur(5px);

	padding: 32px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const StyledBrand = styled.a`
	cursor: pointer;
	text-decoration: none;
	font-family: "PT Mono", monospace;
	color: #fff;
	opacity: 1;
	flex: 1;
	margin-left: 16px;

	.blinker {
		animation: cursor-blink 1.5s steps(2) infinite;
	}

	@keyframes cursor-blink {
		0% {
			opacity: 0;
		}
	}
`;

export const StyledActions = styled.div`
	font-family: "PT Mono", monospace;
	opacity: 0.9;
	cursor: pointer;
	min-width: 0;
	margin-right: 16px;

	a {
		text-decoration: none;
		color: #fff;
		width: fit-content;

		box-shadow: inset 0 -5px 0 rgba(255, 255, 255, 0.2);

		&:hover,
		&:focus {
			box-shadow: inset 0 -10px 0 rgba(255, 255, 255, 0.5);
		}
	}

	&:hover,
	&:focus {
		opacity: 1;
	}
`;
