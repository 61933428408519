import { Outlet } from "react-router-dom";
import { Navbar } from "./Navbar";
import { StyledStandarBody } from "./StandardBody.styled";

export const StandardBody = () => {
	return (
		<StyledStandarBody>
			<Navbar />
			<Outlet />
		</StyledStandarBody>
	);
};
