import { Cover } from "../home/components/Stars";
import {
	StyledCanvas,
	StyledErrorMessage,
	StyledErrorPage,
} from "./404.styled";

export const Error404 = () => {
	return (
		<StyledErrorPage>
			<StyledErrorMessage>404. Isn't it cold here?</StyledErrorMessage>
			<StyledCanvas camera={{ position: [0, 0, 1], zoom: 0.5 }}>
				<Cover />
			</StyledCanvas>
		</StyledErrorPage>
	);
};
