import { Canvas } from "@react-three/fiber";
import styled from "styled-components";

export const StyledErrorPage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
`;

export const StyledErrorMessage = styled.h1`
	font-family: "PT Mono", monospace;
	color: #fff;
	z-index: 1;
`;

export const StyledCanvas = styled(Canvas)`
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;
