import { useState, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Points, PointMaterial } from "@react-three/drei";
//@ts-ignore
import * as random from "maath/random/dist/maath-random.esm";

export const Cover = (props: any) => {
	const ref = useRef();
	const [sphere] = useState(() =>
		random.inSphere(new Float32Array(2000), { radius: 2 })
	);

	useFrame((_, delta) => {
		if (!!(ref?.current as any)?.rotation) {
			(ref.current as any).rotation.x -= delta / 100;
			(ref.current as any).rotation.y -= delta / 150;
		}
	});

	return (
		<group rotation={[0, 0, Math.PI / 4]}>
			<Points
				ref={ref}
				positions={sphere}
				stride={3}
				frustumCulled={false}
				{...props}
			>
				<PointMaterial
					transparent
					color="#fff"
					size={0.005}
					sizeAttenuation={true}
					depthWrite={false}
				/>
			</Points>
		</group>
	);
};
